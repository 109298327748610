@import "../index";
.position-relative {
  position: relative; /* Pour permettre à l'enfant positionné absolument de se référer à cet élément */
}

.formInput {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 10px;


  .formInputField {
    background-color: transparent;
    border-radius: 6px;
    padding: 15px 30px;
    border: 1px solid #6887dc;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Be Vietnam Pro', sans-serif;
    margin: 10px;


    &::placeholder {
      color: #fff;
      font-weight: 500;
      font-family: 'Be Vietnam Pro', sans-serif;
    }

    &:focus {
      outline: none;
      border: 1px solid $blue-border;
      //box-shadow: 0 0 18px $blue;
    }
  }

  .formLabelField {
    margin-bottom: 2px;
    font-weight: 500;
    font-family: 'Be Vietnam Pro', sans-serif;
  }
}
.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  z-index: 1000; /* Assurez-vous que la boîte de dialogue est au-dessus des autres éléments */
}

.dialog-content {
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

