@import "../../index";

.howto-card {
  display: flex;
  flex-direction: column;

  .howto-guide {
    background-color: $blue-dark;
    margin: 15px;
    padding: 40px;
    border-radius: 20px;
    border: 3px solid $blue-border;
    height: 85%;

    .top {
      text-align: center;

      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 24px;
        border: 2px solid $grey-light;
      }

      h3{
        margin-bottom: 10px;
      }
    }

    .content {
      margin: 24px 0 34px 0;

      p {
        margin-bottom: 15px;
      }
    }
  }

  .bottom {
    text-align: center;
    margin-top: -42px;
  }
}
