@import "../../index";

// ------------------------------------------- //
// CONTACT                                     //
// ------------------------------------------- //

.white-wave {
  background-image: url("../../assets/waves/contact/wave-top-white.svg");
}
.blue-wave {
  background-image: url("../../assets/waves/contact/wave-contact-top-blue.svg");
}

.home-contact {
  background-color: $blue-dark;
  text-align: center;
  color: #fff;
  padding: 15% 0 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;


  .center {
    margin: 0 25%;
  }

}