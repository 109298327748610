@import "../../index";

.button-scroll-to-top {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    background-color: unset;
    color: #fff;
    padding: 20px 15px;
    border-radius: 30px;
    border: 1px solid $blue;
    margin-top: -30px;

    &:hover {
      background-color: $blue;
      box-shadow: 0 0 60px $blue;
      cursor: pointer;
    }
  }

  span {
    color: $blue;
    font-size: 10px;
    margin-top: 2px;
  }
}

