@import "./resources";

// ------------------------------------------- //
// CENTER SECTION                              //
// ------------------------------------------- //

.how-to-guide-center-section {
  margin-top: -5px;
  margin-bottom: 0;
  padding: 10px 0;
  background-color: #fff;
  //background-image: url("../../../assets/waves/products/wave-2.svg");

  display: flex;
  flex-direction: row;
  justify-content:  center;
  flex-wrap: wrap;
  //.wrapper-demi {
  //  gap: 30px;
  //}
  .center {

    .line-cards {
      margin-top: 50px;
    }

    .card {
      height: unset;

      p {
        margin-bottom: 20px;
      }
    }

    p {
      margin-bottom: 15px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .card-howto{
    flex: 0 0 50%;
    padding: 10%;
  }
}
