@import "../../index";

.logo {
  //background-color: darkslategrey;
  margin-top: 5px;

  img {
    width: 230px;
  }
}
.navitem {

  .active {
    margin-top: 4px;
    border-bottom: 4px solid $orange;
  }

  i {
    margin-left: 6px;
  }
}
.navitem {
  margin-right: 3rem;
  display: flex;
  align-items: center;
  height: 80px;

  a {
    color: #fff;
    padding: 5px 0;
    text-decoration: none;
  }
  a:hover {
    color: $orange;
  }
}
.navitem:last-child{
  margin-right: 0;
}


.navbar-custom {
  background-color: transparent;
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  .select-mobile {
    display: none;
  }
}
.dropdown-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .item{
    flex: 0 0 30%;
  }
}

.navbar-custom .navbar-brand {
  color: #ffffff;
}

.navbar-custom .nav-link {
  color: #ffffff;
}

.navbar-custom .dropdown-menu.show{
  width: 1000px;
  background-color: white;
  padding: 1%;
}
.navbar-custom .dropdown-menu.show .nav-link{
  border-color: white;
  color: $blue-dark;
}
.navbar-custom .dropdown-menu.show .nav-link:hover {
  color: $orange;
}

.navbar-custom .dropdown-toggle.nav-link{
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 22px;
  font-weight: 500;
}
.navbar-custom .nav-link.active,.navbar-custom .nav-link.show{
  color:$orange;
}
.navbar-custom .dropdown-item:hover {
  color: $orange; /* Couleur de survol */
  background: transparent;
  //text-decoration: $orange underline;
  .active {
    margin-top: 4px;
    border-bottom: 4px solid $orange;
  }
}


.navbar-custom .dropdown-header {
  color: #0c8aff;
  font-size: 1.5rem;
}


//.navbar-custom .dropdown-menu {
//  background-color: #101c3d;
//}

.navbar-custom .dropdown-item {
  color: $blue-dark;
}


.navbar-custom .nav-item {
  margin-right: 3rem;
  display: flex;
  align-items: center;
  height: 80px;

  a {
    color: #fff;
    padding: 5px 0;
    text-decoration: none;
  }

  a:hover {
    color: $orange;
  }
}

.navbar-custom .nav-item:last-child {
  margin-right: 0;
}

.navbar-custom .select-mobile {
  display: none;
}

@media screen and (max-width: 650px) {
  .navbar-custom {
    width: 600px;
  }
  .navbar-custom .dropdown-menu.show{
    top: 1%;
    width: 90%;
    height: 70%;
    position: fixed;
    overflow: auto;
  }
  .select-mobile {
    display: block!important;
    width: fit-content!important;
  }
  .select-desktop {
    display: none;
  }
}


@media screen and (max-width: 1160px) {

  .navbar-custom {
    width: fit-content;
    height:auto;
  }
  .navbar-custom .dropdown-menu.show{
    position:fixed;
    top:1%;
    width: 80%;
    overflow: auto;
  }
  .select-mobile {
    display: block!important;
    width: fit-content!important;
  }
  .select-desktop {
    display: none;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

}