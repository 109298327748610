@import "./colors.scss";

@font-face {
  font-family: 'BeVietnamPro';
  src: url('../../../public/fonts/Be_Vietnam_Pro/BeVietnamPro-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'BeVietnamPro';
  src: url('../../../public/fonts/Be_Vietnam_Pro/BeVietnamPro-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'BeVietnamPro';
  src: url('../../../public/fonts/Be_Vietnam_Pro/BeVietnamPro-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'BeVietnamPro';
  src: url('../../../public/fonts/Be_Vietnam_Pro/BeVietnamPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'BeVietnamPro';
  src: url('../../../public/fonts/Be_Vietnam_Pro/BeVietnamPro-Bold.ttf') format('truetype');
  font-weight: 700;
}

h1 {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 50px;
}

h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 70px;
}

h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 30px;
}

h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 12px;
}

.span-orange {
  color: $orange !important;
}

.span-blue {
  color: $blue !important;
}

.txt-color-dark {
  color: $blue-dark;
}

.txt-color-white {
  color: #fff;
}

a {
  text-decoration: none;
}

.p20-semibold {
  font-size: 20px;
  font-weight: 600;
}
.p20-medium {
  font-size: 20px;
  font-weight: 500;
}
.p20-regular, p {
  font-size: 20px;
  font-weight: 400;
}
.p20-light {
  font-size: 20px;
  font-weight: 300;
}
.p18-medium {
  font-size: 18px;
  font-weight: 500;
}
.p18-regular {
  font-size: 18px;
  font-weight: 400;
  word-wrap:normal;
}
.p16-regular {
  font-size: 16px;
  font-weight: 400;
}

strong {
  font-weight: 600;
}

// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //


@media only screen and (max-width: 650px) {

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .p20-semibold {
    font-size: 18px;
    font-weight: 600;
  }
  .p20-medium {
    font-size: 18px;
    font-weight: 500;
  }
  .p20-regular, p {
    font-size: 18px;
    font-weight: 400;
  }
  .p20-light {
    font-size: 18px;
    font-weight: 300;
  }
  .p18-medium {
    font-size: 16px;
    font-weight: 500;
  }
  .p18-regular {
    font-size: 16px;
    font-weight: 400;
  }
  .p16-regular {
    font-size: 14px;
    font-weight: 400;
  }
}
