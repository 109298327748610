@import "../index";

// ------------------------------------------- //
// TOP SECTION                                 //
// ------------------------------------------- //

.home-top-section {
  background-color: $blue-dark;
  height: 130vh;
  background-image: url("../assets/waves/home/wave-n-data.svg");

  .title {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 100px);
    color: #fff;
  }
}

// ------------------------------------------- //
// PRODUCTS AND SERVICES                       //
// ------------------------------------------- //

.home-products-services {
  background-image: url("../assets/waves/home/wave-white.svg");
  padding: 6% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $blue-alphawave;
  margin-top: -10px;
  color: #fff;

  .center {
    margin: 0 20%;
  }

  .content {
    text-align: center;
    width: 100%;

    .line-cards {
      text-align: left;
      width: 100%;
      color: #fff;

    }
  }
}


// ------------------------------------------- //
// WHY OSIRIS                                  //
// ------------------------------------------- //


.home-why-osiris {
  background-color: #fff;
  padding: 150px 0;
  margin-top: -10px;
  color: $blue-dark;

  p {
    margin-bottom: 15px;
  }
  p:last-child {
    margin-bottom: 0;
  }

  .left {

    .title-n-description {
      margin-right: 60px;
      padding: 15px;
      color: $blue-dark;
      display: flex;
      flex-direction: column;
      height: fit-content;

      h2 {
        color: $blue-dark;
        margin-bottom: 40px;
      }
    }

  }
}


// ------------------------------------------- //
// MANAGE WAVES                                //
// ------------------------------------------- //

.wave-section {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.wave-section-top {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}


// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //

@media only screen and (max-width: 1550px) {

  .home-products-services {

    .center {
      margin: 0 15%;
    }
  }
}

@media only screen and (max-width: 1400px) {

  .center {
    //background-color: mediumslateblue;
  }

  .home-top-section {
    height: 100vh;
  }
  .wave-section {
    background-size: 150%;
  }

  .home-why-osiris {
    .center{
      .title-n-description {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 1160px) {

  .home-top-section {
      background-image: url("../assets/waves/home/wave-n-data-tab.svg");
    .title {
      width: 100%;
      height: 70vh;
    }
  }

  .home-why-osiris {

    .center {

      .title-n-description {
        width: auto;
        margin-bottom: 30px;
      }
      .l1, .l2 {
        justify-content: unset;
      }

      .line {
        //background-color: blueviolet;
        display: flex;
        flex-direction: column;

        .modelisation,
        .transformation,
        .reporting {
          width: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {

  .home-top-section {
    height: 90vh;
    background-image: url("../assets/waves/home/wave-n-data-mobile.svg");
  }

  .wave-section {
    background-size: 190%;
  }

  .home-why-osiris,
  .home-products-services {
    //padding: 100px 0;
  }

  .home-contact{
    padding: 200px 0 100px 0;

    .center {
      margin: 0 30px;
    }

  }
}

@media only screen and (max-width: 600px) {

  .home-top-section {
    height: 80vh;
  }
}

