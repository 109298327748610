@import "./features";




// ------------------------------------------- //
// CENTER SECTION                            //
// ------------------------------------------- //

.whatis-center-section {
  background-color: #fff;
  //color: $blue-dark;
  margin-top: -1px;
  padding-top: 0%;
  display: flex;
  flex-direction: row;
  justify-content:  space-evenly;
  flex-wrap: wrap;

  .card-big-icon {
    h3{
      color:$orange!important;
    }
    flex: 0 0 40%;
    color: white;
    background-color: $blue-dark ;
    border: 1px solid $orange ;
    text-align: center;
    border-radius: 10px;
    margin: 35px 15px;
    padding: 40px 20px;

    p {
      margin-bottom: 15px;
    }

    .icon {
      color: $orange;
      width: 96px;
      height: 96px;
      margin: -90px auto 20px auto;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #ffd0a4;

      i {
        font-size: 40px;
      }
    }
  }

  .bottom {
    margin: 15px;

    p {
      margin-bottom: 20px;
    }
  }
}

// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //


@media only screen and (max-width: 650px) {
  .card-big-icon {
    flex: unset!important;
  }
}