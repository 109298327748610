@import "./features";

// ------------------------------------------- //
// CENTER SECTION                              //
// ------------------------------------------- //

.how-do-it-center-section {
  background-color: #fff;
  color: $blue-dark;
  padding-top: 0%;
  margin-top: -1px;

  .snd-bg {
    padding-bottom: 0%;

    .title-n-text {
      margin: 15px;
    }

    .unique {
      width: 50%;
      margin: auto;
    }

    .case {
      height: unset !important;
    }
  }

}

