@import "../../index";

.case {
  color: $blue-dark;
  background-color: #fff;
  border: 3px solid $blue-border;
  display: flex;
  flex-direction: column;
  padding: 60px 30px;
  margin: 15px;
  height: fit-content;
  border-radius: 20px;

  p {
    margin-bottom: 20px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .title-n-icon {
    display: flex;
    flex-direction: row;
    align-content: center;
    i {
      color: $orange;
      margin-right: 20px;
      font-size: 2rem;
    }
  }
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Espace entre le titre et le bouton */
    align-items: center; /* Centrer verticalement */
    padding: 10px; /* Ajoute du rembourrage autour du header */


    .close-button{
      display:flex;
      background: transparent; /* Pas de fond */
      border: none; /* Pas de bordure */
      cursor: pointer; /* Change le curseur au survol */
      z-index: 1; /* Assure que le bouton est au-dessus d'autres éléments */
      i {
        color: $orange;
        margin-right: 20px;
        font-size: 2rem;
      }
    }
  }

}

.dark-card-with-border {
  color: #fff !important;
  background-color: $blue-dark-l1 !important;
  border: 3px solid $blue-dark-l2 !important;
}

.blue-card-with-border {
  color: #fff !important;
  background-color: #2449a8 !important;
  border: 3px solid #365bbc !important;
}
