@import "../../index";

// ------------------------------------------- //
// MANAGE WAVES                                //
// ------------------------------------------- //

.wave-section {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.wave-section-top {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

// ------------------------------------------- //
// TOP SECTION                                 //
// ------------------------------------------- //

.top-section {
  margin-top: -110px;
  background-image: url("../../assets/waves/osiris/wave-0-top.svg");

  .title {
    //background-color: #794a10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 65vh;

    .line {
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      //background-color: blueviolet;

      .logo {
        background-color: #fff;
        padding: 20px;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 14px 32px rgba(16, 28, 61, 0.2);

        svg {
          width: 80%;
        }

      }
      h1 {
        margin: 0;
      }
    }
  }
}
// ------------------------------------------- //
// BOUTTONS                                  //
// ------------------------------------------- //

.buttons-section{
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //

@media only screen and (max-width: 1620px) {
  .osiris-plateform-tab-section {
    .center {
      margin: 0 60px!important;
    }
  }
}

@media only screen and (max-width: 1420px) {


  .top-section {

    .title {
      width: 100%;
      height: unset;
      padding: 200px 0 100px 0;

      h1 {
        color: #fff;
      }
    }
  }
}



@media only screen and (max-width: 1000px) {

  .center-section {
    .snd-bg {
      .unique {
        width: 100%;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 650px) {

  .top-section {
    .title {
      .line {
        .logo {
          padding: 15px;
          width: 60px;
          height: 60px;
        }
      }
    }
  }

}
