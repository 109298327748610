@import "../index";

// ------------------------------------------- //
// MANAGE WAVES                                //
// ------------------------------------------- //

.wave-section {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.wave-section-top {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

.privacy-policy-top-section,
.privacy-policy-sec2,
.privacy-policy-sec3,
.privacy-policy-sec4{

  .p18-medium {
    margin-bottom: 15px;
  }
}

// ------------------------------------------- //
// TOP SECTION                                 //
// ------------------------------------------- //

.privacy-policy-top-section {
  background-color: $blue-dark;
  height: 60vh;
  background-image: url("../assets/waves/products/wave-blue-1.svg");

  .title {
    padding: 100px 0;
  }
}

// ------------------------------------------- //
// SECTION 2                                   //
// ------------------------------------------- //

.privacy-policy-sec2 {
  margin-top: -5px;
  padding-bottom: 15%;
  background-color: $blue-alphawave;
  background-image: url("../assets/waves/osiris/wave-0-top.svg");
}

// ------------------------------------------- //
// SECTION 3                                   //
// ------------------------------------------- //

.privacy-policy-sec3  {
  background-color: #fff;
  color: $blue-dark;
  margin-top: -30px;
  padding-top: 5%;

  h2 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .case {
    height: unset;
  }
}

// ------------------------------------------- //
// SECTION 4                                   //
// ------------------------------------------- //

.privacy-policy-sec4 {
  color: $blue-dark;
  padding-bottom: 8%;
  background-image: url("../assets/waves/contact/wave-top-white.svg");

  .bg-white {
    padding-top: 8%;
  }
}
