@import "../index";

// ------------------------------------------- //
// MANAGE WAVES                                //
// ------------------------------------------- //

.wave-section {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.wave-section-top {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

// ------------------------------------------- //
// TOP SECTION                                 //
// ------------------------------------------- //

.osiris-top-section {
  margin-top: -110px;
  background-image: url("../assets/waves/osiris/wave-0-top.svg");

  .title {
    //background-color: #794a10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 65vh;

    .line {
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      //background-color: blueviolet;

      .logo {
        background-color: #fff;
        padding: 20px;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 14px 32px rgba(16, 28, 61, 0.2);

        svg {
          width: 80%;
        }

      }
      h1 {
        margin: 0;
      }
    }
  }
}

// ------------------------------------------- //
// SOLUTION SECTION                            //
// ------------------------------------------- //

.solution-section {
  margin-top: -10px;
  padding: 5% 0 24% 0;
  background-image: url("../assets/waves/osiris/wave-1.svg");
  background-color: #fff;
  color: $blue-dark;

  h2 {
    text-align: center;
  }

  .card-big-icon {
    color: $blue-dark;
    background-color: #fff;
    border: 3px solid $blue-border;
    text-align: center;
    border-radius: 10px;
    margin: 35px 15px;
    padding: 40px 20px;

    p {
      margin-bottom: 15px;
    }

    .icon {
      color: $orange;
      width: 96px;
      height: 96px;
      margin: -90px auto 20px auto;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #ffd0a4;

      i {
        font-size: 40px;
      }
    }
  }

  .bottom {
    margin: 15px;

    p {
      margin-bottom: 20px;
    }
  }
}

// ------------------------------------------- //
// OSIRIS CONSTRUCTION SECTION                 //
// ------------------------------------------- //

.osiris-construction-section {
  background-color: #fff;
  color: $blue-dark;
  background-image: url("../assets/waves/osiris/wave-2.svg");
  padding-top: 25%;

  .snd-bg {
    background-image: url("../assets/waves/osiris/wave-3.svg");
    padding-bottom: 25%;

    .title-n-text {
      margin: 15px;
    }

    .unique {
      width: 50%;
      margin: auto;
    }

    .case {
      height: unset !important;
    }
  }

}

// ------------------------------------------- //
// OSIRIS TAB SECTION                          //
// ------------------------------------------- //

.osiris-plateform-tab-section {
  background-color: $blue-alphawave;
  margin-top: -10px;

  .left, .right {
    margin: 15px;
    //background-color: brown;
  }

  .tabs-group {
    margin-bottom: 30px;
  }

  .tab{
    font-family: 'BeVietnamPro', sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-transform: unset !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: 'BeVietnamPro', sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-transform: unset !important;
    color: rgba(255, 255, 255, 0.7) !important;

  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-family: 'BeVietnamPro', sans-serif !important;
    //color: #53acff !important;
    color: $orange !important;
  }
  .css-1usuzwp-MuiButtonBase-root-MuiTab-root{
    font-family: 'BeVietnamPro', sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-transform: unset !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }
  .css-1usuzwp-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-family: 'BeVietnamPro', sans-serif !important;
    //color: #53acff !important;
    color: $orange !important;
  }



  .css-1aquho2-MuiTabs-indicator {
    background-color: $orange !important;

  }

  .css-1gsv261 {
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.1) !important;
  }

  .left {
    p {
      margin-bottom: 15px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .right {

    img {
      border-radius: 10px;
      width: 100%;
      height: 450px;
      object-fit: contain;
    }
  }
}



// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //

@media only screen and (max-width: 1620px) {
  .osiris-plateform-tab-section {
    .center {
      margin: 0 60px!important;
    }
  }
}

@media only screen and (max-width: 1420px) {

  .osiris-plateform-tab-section {
    .center {
      margin: 0 60px!important;
    }
  }

  .osiris-top-section {

    .title {
      width: 100%;
      height: unset;
      padding: 200px 0 100px 0;

      h1 {
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 1120px) {

  .osiris-plateform-tab-section {

    .wrapper-demi {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media only screen and (max-width: 1000px) {

  .osiris-construction-section {
    .snd-bg {
      .unique {
        width: 100%;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 650px) {

  .osiris-top-section {
    .title {
      .line {
        .logo {
          padding: 15px;
          width: 60px;
          height: 60px;
        }
      }
    }
  }
  /* Styles pour les écrans plus petits */
  @media (max-width: 600px) {
    .tabs-group  {
      flex-direction: column; /* Empile les onglets sur les petits écrans */
    }
  }
  .osiris-plateform-tab-section {
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      flex-direction: column; /* Empile les onglets sur les petits écrans */
      font-size: 11px !important;
    }
    i {
      font-size: 15px !important;
    }

  }
  .osiris-plateform-tab-section {
    .css-1usuzwp-MuiButtonBase-root-MuiTab-root {
      flex-direction: column; /* Empile les onglets sur les petits écrans */
      font-size: 8px !important;
    }
    i {
      font-size: 12px !important;
    }
  }
}
