.wrapper-quart {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.wrapper-tiers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.wrapper-demi {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //

@media only screen and (max-width: 1420px) {

  footer, .solution-section {
    .wrapper-quart {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}


@media only screen and (max-width: 1000px) {

  .wrapper-demi {
    grid-template-columns: repeat(1, 1fr);
  }
  .wrapper-tiers {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 850px) {


  .solution-section {
    //.wrapper-quart {
    //  grid-template-columns: repeat(1, 1fr);
    //}
  }
}

@media only screen and (max-width: 650px) {

  .wrapper-tiers {
    grid-template-columns: repeat(1, 1fr);
  }
  .wrapper-quart {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .wrapper-demi {
    grid-template-columns: repeat(1, 1fr);
  }
}
