@import "../../index";

// BUTTONS

.primary-button {
  margin-top: 50px;
  padding: 15px 30px;
  border: 1px solid $blue;
  border-radius: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: none;
  width: fit-content;
  backdrop-filter: blur(20px);

  &:hover {
    background: $blue;
    box-shadow: 0 0 60px $blue;
    color: #fff !important;
    cursor: pointer;
  }
}

.big-button {
  margin-top: 70px !important;
  padding: 25px 60px !important;
}

.button-in-profile {
  margin-top: 0;
  color: $blue-dark;
  background-color: #fff;
}

.btn-center {
  text-align: center;
}

@media only screen and (max-width: 700px) {

  .big-button {
    margin-top: 50px !important;
    padding: 15px 30px !important;
  }
}