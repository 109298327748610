@import "../../index";

footer {
  background-color: $blue-dark;
  color: #fff;

  .bg-left{
    background-image: url("../../assets/waves/footer/foot-data-left.svg");
    background-position: bottom left;
    background-repeat: no-repeat;

  }
  .bg-right {
    padding: 40px 0;
    background-image: url("../../assets/waves/footer/foot-data-right.svg");
    background-position: bottom right;
    background-repeat: no-repeat;
  }


  .center {
    display: flex;
    flex-direction: column;

    .scroll-to-top {
      display: flex;
      justify-content: flex-end;
    }

    .content {
      display: flex;
      flex-direction: row;

      .logo {
        width: 30%;
        margin: 15px;
      }

      .right {
        width: 100%;

        .col {
          display: flex;
          flex-direction: column;
          margin: 15px;

          .footer-item {
            margin-bottom: 15px;
          }

          .icon-text {
            display: flex;
            flex-direction: row;

            i {
              margin-right: 10px;
              font-size: 20px;
            }
          }

          span {
            margin-bottom: 30px;
          }

          a {
            color: #fff;
          }
          a:hover {
            color: $blue;
          }
        }
      }

      .copyright {
        padding-top: 35px;
        color: #759aff;
        margin: 15px;

      }
    }
  }
}


// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //


@media only screen and (max-width: 900px) {

  footer {
    //background-color: #eadb7f;

    .center {

      .right {
        padding-bottom: 100px;

        .col {

          span {

            font-size: 18px;
            font-weight: 600;
            margin-bottom: 25px;
          }

          p, a {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 12px;
          }

        }

        .copyright {
          padding-top: 20px;
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {

  footer {
    //background-color: #ff4040;

    .bg-left{
      background-image: none;
      background-position: unset;
      background-repeat: unset;

    }

    .center {
      display: block;

      .content {
        flex-direction: column;

        .logo {
          width: calc(100% - 30px);
        }

        .right {
          padding-bottom: 180px;
          width: 100%;

          .col {

            span {

              font-size: 14px;
              font-weight: 600;
              margin-bottom: 20px;
            }

            p, a {
              font-size: 10px;
              font-weight: 400;
              margin-bottom: 10px;
            }

          }

          .copyright {
            font-size: 10px;
          }
        }
      }
    }
  }
}