@import "../../index";


.nav-item {

  .active {
    margin-top: 4px;
    border-bottom: 4px solid $orange;
  }

  i {
    margin-left: 6px;
  }
}

.blue-header {
  position: sticky;
  top: 0;
  z-index: 1000 !important;
  background-color: transparent;
  width: 100%;
  padding: 10px 0;
  color: #fff;
  transition: all ease 3ms;

  .center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .menu-icon {
      display: none;
    }

    .logo {
      //background-color: darkslategrey;
      margin-top: 5px;

      img {
        width: 230px;
      }
    }

    nav {
      display: flex;
      flex-direction: row;

      .nav-item {
        margin-right: 3rem;
        display: flex;
        align-items: center;
        height: 80px;

        a {
          color: #fff;
          padding: 5px 0;
          text-decoration: none;
        }
        a:hover {
          color: $orange;
        }
      }
      .nav-item:last-child{
        margin-right: 0;
      }

      .select-mobile {
        display: none;
      }
    }
  }
}
.blue-header.active {
  background-color: $blue-dark;
}

select {
  color: #fff;
  background-color: $orange;
  border: 1px solid $blue;
  padding: 8px 15px;
  border-radius: 5px;
  font-family: 'BeVietnamPro', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

@media screen and (max-width: 1350px) {
  .blue-header {

    .center {

      nav {

        .nav-item {
          margin-right: 2rem;
        }
      }
    }
  }
}


@media screen and (max-width: 1160px) {

  .blue-header {
    padding: 25px 0;
    background-color: $blue-dark;

    .center {

      .menu-icon {
        display: block;
        font-size: 1.8rem;
        cursor: pointer;
      }

      .nav-menu {
        display: flex;
        padding: 60px;
        flex-direction: column;
        width: calc(100% - 120px);
        text-align: center;
        height: 100vh;
        position: absolute;
        top: 60px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;

        .nav-item {
          text-align: center;
          //background-color: orange;
          margin-right: 0;
          width: 100%;

          .nav-links {
            text-align: center;
            display: table;
          }
        }

        .select-mobile {
          display: block;
          width: fit-content;
        }
      }
      .nav-menu.nav-active {
        background: $blue-dark;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
    }
  }

  .select-desktop {
    display: none;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

}

@media screen and (max-width: 650px) {
  .blue-header {
    .center {
      .logo {
        //max-width: 80px;
      }
    }
  }
}