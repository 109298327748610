@import "../../index";

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border: solid 3px $blue-border;
  color: $blue-dark;
  border-radius: 12px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 15% 70px 15%;

  a {
    color: $blue;
  }

  p {
    margin-right: 30px;
  }

  button {
    margin-top: 0;
    color: $blue-dark;
  }
}

// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //

@media only screen and (max-width: 1420px) {
  .cookie-consent {
    margin: 0 8% 70px 8%!important;
  }
}

@media only screen and (max-width: 1000px) {

  .cookie-consent {
    margin: 0 60px 50px 60px!important;
  }
}

@media only screen and (max-width: 650px) {

  .cookie-consent {
    margin: 0 30px 30px 30px!important;
    flex-direction: column;
    align-items: start;

    button {
      margin-top: 30px;
    }
  }
}