@import "./features";




// ------------------------------------------- //
// CENTER SECTION                            //
// ------------------------------------------- //

.whatdoes-center-section {
  background-color: #fff;
  color: $blue-dark;
  margin-top: -1px;
  padding-top: 0%;

  h2 {
    text-align: center;
  }


  .card-big-icon {
    color: $blue-dark;
    background-color: #fff;
    border: 3px solid $blue-border;
    text-align: center;
    border-radius: 10px;
    margin: 35px 15px;
    padding: 40px 20px;

    p {
      margin-bottom: 15px;
    }

    .icon {
      color: $orange;
      width: 96px;
      height: 96px;
      margin: -90px auto 20px auto;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #ffd0a4;

      i {
        font-size: 40px;
      }
    }
  }

  .bottom {
    margin: 15px;

    p {
      margin-bottom: 20px;
    }
  }
}

