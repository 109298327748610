@import "../index";

// ------------------------------------------- //
// MANAGE WAVES                                //
// ------------------------------------------- //

.wave-section {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.wave-section-top {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

// ------------------------------------------- //
// TOP SECTION                                 //
// ------------------------------------------- //

.products-top-section {
  background-color: $blue-dark;
  //background-color: darkslategray;
  height: 60vh;
  background-image: url("../assets/waves/products/wave-blue-1.svg");

  .title {
    padding: 100px 0;
    //background-color: navajowhite;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    color: #fff;
  }
}

// ------------------------------------------- //
// DEPLOIMENT SECTION                          //
// ------------------------------------------- //

.products-deploiment-section {
  margin-top: -5px;
  margin-bottom: 0;
  padding: 10px 0;
  background-color: $blue-alphawave;
  background-image: url("../assets/waves/products/wave-2.svg");

  .center {

    .line-cards {
      margin-top: 50px;
    }

    .card {
      height: unset;

      p {
        margin-bottom: 20px;
      }
    }

    p {
      margin-bottom: 15px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

// ------------------------------------------- //
// COMPARE OFFERS SECTION                      //
// ------------------------------------------- //

.products-compare-offers-section {
  background-color: #fff;
  padding: 150px 0 50px 0;
  margin-top: -10px;
  color: $blue-dark;

  .case {
    height: unset;
  }

  .icon-n-check.special {
    .p18-regular {
      min-width: 80%;
    }
    .spe {
      text-align: right !important;
      display: flex;
      justify-content: flex-end;
      color: $orange;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .icon-n-check {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    margin-bottom: 20px;
  }
  .icon-n-check:last-child {
    margin-bottom: 0;
  }
}

// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //

@media only screen and (max-width: 1720px) {
  .products-compare-offers-section,
  .products-deploiment-section {
    .center {
      margin: 0 60px;
    }
  }
}

@media only screen and (max-width: 1420px) {
  .products-compare-offers-section,
  .products-deploiment-section {
    .wrapper-tiers {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .products-compare-offers-section,
  .products-deploiment-section {
    .wrapper-tiers {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}