@import "../../index";

// DEFAULT CARD ---------------- //

.card {
  background: $blue-dark;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 90px 60px;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  margin: 15px;

  h3 {
    color: $orange;
  }

  a {
    color: #fff;
    margin-right: 10px;
  }

}
.card-hover:hover {
  background: $blue-alphawave;
  box-shadow: 0 14px 97px rgba(16, 28, 61, 0.9);

  h3, p {
    color: #fff;
  }
}
.card-hover:hover a{
  //margin-right: 40px;
  color: #fff !important;
}

// GREY CARD ------------------- //

.grey-card {
  background: $grey-light !important;
  color: $blue-dark!important;

  p {
    color: $blue-dark!important;
  }

  a {
    color: $blue-dark!important;
  }
  .arrow {
    fill: $blue-dark!important;
  }

}
.grey-card.card-hover:hover {
  background: $blue-alphawave!important;

  h3, p {
    color: #fff!important;
  }

  .arrow {
    fill: #fff!important;
  }
}
.grey-card.card-hover:hover a{
  //margin-right: 40px;
  color: #fff !important;
}


@media only screen and (max-width: 1350px) {

  .card, .grey-card {
    height: 270px;
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 850px) {

  .card, .grey-card {
    height: 220px;
  }
}


