@import "../index";

// ------------------------------------------- //
// MANAGE WAVES                                //
// ------------------------------------------- //

.wave-section {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.wave-section-top {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

// ------------------------------------------- //
// TOP SECTION                                 //
// ------------------------------------------- //

.contact-top-section {
  background-color: $blue-dark;
  height: 55vh;
  background-image: url("../assets/waves/contact_page/wave-top-1.svg");

  .title {
    padding: 100px 0;
    color: #fff;
  }
}


.contact-page {
  background-color: $blue-alphawave;
  //background-image: url("../assets/waves/contact_page/wave-2.svg");
  padding-bottom: 350px;

  .left {
    padding: 90px 60px;

    .wrapper-demi {
      gap: 30px;
    }

    .col {
      display: flex;
      flex-direction: column;
      margin: 15px 0;

      span {
        margin-bottom: 10px;
      }

      a {
        color: #fff;
      }
      a:hover {
        color: $blue;
      }

      .icon-text {
        display: flex;
        flex-direction: row;

        i {
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }

  .right {
    color: #fff;
    background-color: #2449a8;
    border: 3px solid #365bbc;
    border-radius: 20px;
    padding: 90px 60px;


    form {
      display: flex;
      flex-direction: column;

      .formInput {
        display: flex;
        flex-direction: column;
        margin: 15px 0;

        label {
          margin-bottom: 2px;
          font-weight: 500;
          font-family: 'Be Vietnam Pro', sans-serif;
        }

        input, textarea {
          background-color: transparent;
          border-radius: 6px;
          padding: 15px 30px;
          border: 1px solid #6887dc;
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          font-family: 'Be Vietnam Pro', sans-serif;

          &::placeholder {
            color: #fff;
            font-weight: 500;
            font-family: 'Be Vietnam Pro', sans-serif;
          }

          &:focus {
            outline: none;
            border: 1px solid $blue-border;
            //box-shadow: 0 0 18px $blue;
          }
        }
      }
    }
  }
}

.contact-map-section {
  background-color: #fff;
  color: $blue-dark;
  margin-top: -10px;

  iframe {
    border-radius: 20px;
    width: 100%;
  }
}