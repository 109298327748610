@import "./style/style_guide/typography.scss";
@import "./style/style_guide/colors.scss";
@import "./style/style_guide/grid.scss";

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  margin: 0;
  background-color: $blue-dark;
  color: #fff;
  font-family: 'BeVietnamPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all ease 3ms;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  margin: 0 15%;
}
.flex-container-md2{
  display:flex;
  width: 99%;
  margin:0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  word-wrap: break-word;
  .item{
    flex: 0 0 40%;
    width:100%;
  }
}
.flex-container-md3{
  display:flex;
  width: 99%;
  margin:0 auto;
  flex-direction: row;
  justify-content:center;
  word-wrap: break-word;
  flex-wrap: wrap;
  .item{
    flex: 0 0 30%;
    width:100%;
  }

}

.icon-n-text {
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    margin-right: 10px;
    font-size: 25px;
  }
}

.bg-blue-alphawave {
  background-color: $blue-alphawave;
}
.bg-white {
  background-color: #fff;
}

.m-15 {
  margin: 15px;
}


// ------------------------------------------- //
// RESPONSIVE                                  //
// ------------------------------------------- //

@media only screen and (max-width: 1420px) {
  .center {
    margin: 0 8% !important;
  }
}

@media only screen and (max-width: 1000px) {

  .center {
    margin: 0 60px!important;
  }
}

@media only screen and (max-width: 650px) {

  .center {
    margin: 0 30px!important;
  }

  .flex-container-md2{
    flex-direction: column;
  }
  .flex-container-md2 .item{
    width: auto;
  }
  .flex-container-md3{
    flex-direction: column;
  }
}
