@import "../index";

// ------------------------------------------- //
// MANAGE WAVES                                //
// ------------------------------------------- //

.wave-section {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.wave-section-top {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

.legal-informations-sec2,
.legal-informations-sec3 {
  color: $blue-dark;
  margin-top: -5px;

  .p18-medium {
    margin-bottom: 15px;
  }
}

// ------------------------------------------- //
// TOP SECTION                                 //
// ------------------------------------------- //

.legal-informations-top-section {
  background-color: $blue-dark;
  height: 68vh;
  background-image: url("../assets/waves/mentions/wave-white.svg");

  .title {
    padding: 100px 0;
  }
}

// ------------------------------------------- //
// SECTION 2                                   //
// ------------------------------------------- //

.legal-informations-sec2 {
  padding: 30px 0;
}

// ------------------------------------------- //
// SECTION 3                                   //
// ------------------------------------------- //

.legal-informations-sec3 {

  padding-bottom: 8%;
  background-image: url("../assets/waves/contact/wave-top-white.svg");

  .bg-white {
    padding-top: 8%;
  }
}
