@import "../index";

// ------------------------------------------- //
// MANAGE WAVES                                //
// ------------------------------------------- //

.wave-section {
  background-size: 100%;
  margin-top: -10px;
  margin-bottom: -10px;
  background-position: bottom;
  background-repeat: no-repeat;
}
.wave-section-top {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

// ------------------------------------------- //
// TOP SECTION                                 //
// ------------------------------------------- //

.waw-top-section {
  margin-top: -120px;
  //background-color: sandybrown;
  background-image: url("../assets/waves/whoAreWe/wave-top-1.svg");

  .title {
    width: 60%;
    //background-color: #794a10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70vh;

    h1 {
      color: #fff;
    }
  }
}

// ------------------------------------------- //
// DESCRIPTION SECTION                         //
// ------------------------------------------- //

.waw-description-section {
  padding: 100px 0 300px 0;
  background-image: url("../assets/waves/whoAreWe/wave-2.svg");
  background-color: $blue-alphawave;

  .center {
    margin: 0 20%;

    p {
      margin-bottom: 30px;
    }
  }
}

// ------------------------------------------- //
// TEAM SECTION                                //
// ------------------------------------------- //

.waw-team {
  color: $blue-dark;
  background-color: #fff;
  //background-image: url("../assets/waves/whoAreWe/wave-3.svg");
  padding-bottom: 150px;

  h2 {
    text-align: center;
  }
}

// ------------------------------------------- //
// EXPERTISE SECTION                           //
// ------------------------------------------- //

.waw-expertise {
  margin-top: -10px;
  background-color: $blue-alphawave;

  .container {
    padding-bottom: 50px;

    text-align: center;
    p {
      margin-bottom: 22px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .case {
    height: unset;
  }
}